import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Menu from '../components/Menu'
import Page from '../components/Page'
import PostTemplateDetails from '../components/PostTemplateDetails'

export default props => {
  const { title, subtitle, menu } = props.data.site.siteMetadata
  const post = props.data.markdownRemark
  const { title: postTitle, description: postDescription } = post.frontmatter
  const description = postDescription !== null ? postDescription : subtitle

  return (
    <Layout>
      <Helmet>
        <title>{`${postTitle} - ${title}`}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Menu data={menu} />
      <Page className="mx-auto">
        <PostTemplateDetails {...props} />
      </Page>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
        }
        url
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        description
      }
    }
  }
`
