import React from 'react'
import { format } from 'date-fns'

export default props => {
  const { author } = props.data.site.siteMetadata
  const { frontmatter, html } = props.data.markdownRemark

  return (
    <div className="max-w-4xl mx-auto w-full">
      <div className="text-center">
        <h1 className="text-3xl md:text-title mb-4">{frontmatter.title}</h1>
        <h6 className="mb-10 text-uppercase text-sm md:text-base text-gray-600 uppercase tracking-wide">
          <time>{format(new Date(frontmatter.date), 'dd MMM yyyy')}</time> •{' '}
          {author.name}
        </h6>
      </div>
      <hr />
      <article
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  )
}
